<template>
  <v-container>

    <NavBar :step="0" />

    <Titulo></Titulo>

    <br>

    <br v-if="screen < 800">


    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">
      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="1">
          <div :class="formText">

            <v-form ref="form" v-model="valid" max-witdh="300px">

              <br>
              <br>

              <h3 class="text-center labelHola">¡Hola!</h3>

              <h3 class="text-center labelQuestion">¿Cómo te llamas?</h3>
              <br>

              <v-row>
                <v-col>
                  <v-text-field class="labelNombre" v-model="userData.name" :rules="nameRules" label="Nombre" required
                    maxlength="51" filled solo @change="change()"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field class="labelNombre" v-model="userData.surname" @change="change()" :rules="surnameRules"
                    maxlength="51" label="Apellido" required filled solo></v-text-field>
                </v-col>

              </v-row>
              <br>
              <br>

              <h3 class="text-center labelQuestion">¿Cuál es tu email?</h3>

              <h4 class="text-center labelSubQuestion">Así podremos enviarte tu cotización, descuentos y comunicaciones.
              </h4>

              <v-text-field class="labelEmail" v-model="userData.email" @change="change()" :rules="emailRules"
                maxlength="51" label="Ingresa tu correo electrónico" required filled solo></v-text-field>

              <br>
              <br>

              <h3 class="text-center labelQuestion">¿Cuál es tu edad?</h3>

              <h4 class="text-center labelSubQuestion">Es para recomendarte el mejor seguro.</h4>

              <v-row v-if="screen < 800">

                <v-col>
                  <v-select class="labelDatesMovil" style="max-width: 85px; height: 48px;" :rules="monthRules"
                    :items="months" v-model="userData.month" :loading="loadingMonths" v-on:change="getDays()"
                    item-text="name" label="Mes" return-object required filled solo></v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesMovil" style="max-width: 85px; height: 48px;" :rules="dayRules"
                    :items="days" v-model="userData.day" :loading="loadingDays"
                    :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object
                    required filled solo>
                  </v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesMovil" style="max-width: 85px; height: 48px;" :rules="yearRules"
                    value="2022" :items="years" v-model="userData.year" item-text="name" label="Año" return-object
                    required filled solo></v-select>
                </v-col>

              </v-row>

              <v-row v-else>

                <v-col>
                  <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="monthRules"
                    :items="months" v-model="userData.month" :loading="loadingMonths" v-on:change="getDays()"
                    item-text="name" label="Mes" return-object required filled solo></v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="dayRules"
                    :items="days" v-model="userData.day" :loading="loadingDays"
                    :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object
                    required filled solo>
                  </v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="yearRules"
                    value="2022" :items="years" v-model="userData.year" item-text="name" label="Año" return-object
                    required filled solo></v-select>
                </v-col>

              </v-row>

              <br>
              <br>


              <div class="formData">
                <v-row justify="center">
                  <v-dialog v-model="dialog" width="600px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                      </v-card-title>
                      <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus
                        modificaciones, autorizo expresamente a Southbridge
                        Compañía de Seguros Generales S.A. para que haga tratamiento, almacene, transmita y comunique
                        mis
                        datos
                        personales y la información que le he
                        proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas,
                        sociedades del Grupo Empresarial al que pertenece y
                        terceros prestadores de servicios, estén ubicados dentro o fuera de Chile, con el propósito de
                        que
                        tanto
                        Southbridge Compañía de Seguros Generales
                        S.A. como dichas entidades i) me contacten y pongan a mi disposición, en la forma que estimen
                        pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi
                        información personal para los objetivos del
                        cumplimiento de este Contrato de Seguro; y iii) hagan efectivos el o los convenios que pudieren
                        estar
                        asociados a los seguros que he contratado. Además,
                        por este acto, autorizo expresamente a que cualquier información, comunicación, declaración o
                        notificación que debe efectuar Southbridge Compañía de
                        Seguros Generales S.A. en virtud del presente contrato de seguros sea realizada a través de
                        correo
                        electrónico o de cualquier sistema de transmisión o
                        registro digital de la palabra escrita o verbal o por cualquier otro medio de comunicación
                        fehaciente.
                        El asegurado declara que conoce, acepta y concuerda
                        que cualquier transacción que se realice en el sitio www.sbseguros.cl con su Rut y clave
                        personal, se
                        entenderá y se reputará, para todos los efectos
                        legales, como realizada por él mismo. En tal sentido, el asegurado se hace entera y totalmente
                        responsable del uso y seguridad de su clave, no cabiéndole
                        Southbridge Compañía de Seguros Generales S.A.. ninguna responsabilidad por el uso indebido de
                        la
                        misma
                        por un tercero distinto del asegurado. El
                        asegurable se hace responsable por la veracidad de la información entregada para la contratación
                        del
                        seguro y del uso de las claves o códigos, liberando a
                        Southbridge Compañía de Seguros Generales S.A. de responsabilidad por cualquier mal uso que
                        hagan
                        terceros de la información que Southbridge
                        Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del asegurable.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                                  border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                                  letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                          elevation="0" text @click="dialog = false">
                          Aceptar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>


                <v-hover v-if="screen > 800">
                  <v-checkbox :rules="conditionsRules" :color="stepperColor" required>
                    <template v-slot:label>
                      <p
                        style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323; opacity: 0.8; text-align: justify; max-width: 400px; margin-top: 10%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas que tu información sea utilizada para
                        calcular tu estimación de prima y comunicarnos contigo para éste y otros productos de acuerdo a
                        nuestros <b><a style="color:rgba(0, 44, 119, 1)" @click="dialog = true">Términos y
                            Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>

                <v-hover v-else>
                  <v-checkbox :rules="conditionsRules" :color="stepperColor" required>
                    <template v-slot:label>
                      <p
                        style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323; opacity: 0.8; text-align: justify; margin-top: 20%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas que tu información sea utilizada para
                        calcular tu estimación de prima y comunicarnos contigo para éste y otros productos de acuerdo a
                        nuestros <b><a style="color: #3333CC" @click="dialog = true">Términos y
                            Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>
              </div>


              <div class="button-container">
                <v-btn
                  style="background-color: #2D387C ; color: white; border-radius: 12px; width: 300px; height: 50px;"
                  @click="validate()">
                  Continuar
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue"
import NavBar from "@/components/NavBar.vue";

import swal from 'sweetalert';
const moment = require('moment');
import CryptoJS from 'crypto-js';

export default {
  name: "Step1",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    version: null,
    formText: '',
    stepperColor: '#EDB321',
    edadMayor: null,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      hasAcode: null,
      stepId: null,
      old: null,
      day: null,
      month: null,
      year: null,
      dates: null,
      typePlan: null,
      PlanName: null,
      datesBenef: null,
      sonExist: false,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    dialog: false,
    screen: window.screen.width,
    months: [],
    days: ["Selecciona el mes"],
    yars: [],
    prefijoTelefono: null,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    valid: false,
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],

  }),
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.getMonths();
    this.getYears();

    const query = this.$route.query;
    const userDataAux = sessionStorage.getItem('userData');
    const servicesDataAux = sessionStorage.getItem('servicesData');

    if (query.stepID) {
      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      this.getDaysMounted();

    } else if (userDataAux) {
      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);
      this.getDaysMounted();

      if (servicesDataAux) {
        const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
        const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
        this.servicesData = JSON.parse(decryptedServicesData);
        console.log("serivcesData: ", this.servicesData);
      }

    } else {
      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      sessionStorage.setItem('userData', encryptedUserDataLocal);
    }

    if (this.screen > 800) {
      this.formText = 'formData'
    } else {
      this.formText = ''
    }

  },
  methods: {
    async validate() {
      const fechaActual = new moment(new Date());
      if (this.$refs.form.validate()) {
        var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
        var dateNacimiento = moment(new Date(newDate));
        var timesDiff = fechaActual.diff(dateNacimiento, 'years');
        if (timesDiff < 18) {
          swal({
            title: "Atención!",
            text: "Para contratar este seguro, usted debe ser mayor de edad!",
            icon: "info",
            button: "De acuerdo",
          });
        } else if (timesDiff > 69) {
          swal({
            title: "Atención!",
            text: "Para contratar este seguro, usted debe ser menor a 69 años!",
            icon: "info",
            button: "De acuerdo",
          });
        } else {

          this.userData.dates = newDate;
          this.userData.old = timesDiff;

          this.change();

          const userDataLocal = JSON.stringify(this.userData);
          const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
          sessionStorage.setItem('userData', encryptedUserDataLocal);

          var shieldRes;
          if (this.activeCampaingUse == 'TRUE') {
            const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

            if (activeCampaingRes.data.data.meta.total == 1) {
              this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;
              await ShieldService.updateContactActiveCampain(this.userData, null, this.servicesData, 0);
            } else {
              shieldRes = await ShieldService.createContactActiveCampain(this.userData, this.servicesData);

              if (shieldRes.data.status == 'ok') {
                this.servicesData.contactID = shieldRes.data.data.contacts[0].id;
              }
            }

            const servicesDataLocal = JSON.stringify(this.servicesData);
            const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
            sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

          }

          this.$router.push({ path: '/ap/planes' })
        }

      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.userData.day = null;
        this.days = await dateService.getDays(
          this.userData.month.name
        );
        this.loadingDays = false;
      }
    },
    async getDaysMounted() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.days = await dateService.getDays(
          this.userData.month.name
        );
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    async change() {
      try {

        const servicesDataAux = sessionStorage.getItem('servicesData');

        if (servicesDataAux) {
          const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
          const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
          this.servicesData = JSON.parse(decryptedServicesData);
          console.log("serivcesData: ", this.servicesData);
        }

        var carro = await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Inicio');
        this.servicesData.type = 'update';
        this.servicesData.stepId = carro.data.data._id;

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.labelHola {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.01em; //ojo -0.22px;
  color: #2D387C;
  mix-blend-mode: darken; //ojo
}

.labelNombre {
  box-sizing: border-box;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  margin: auto;
  border-radius: 6px;
}

.labelQuestion {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em; // -0.18px;
  color: #2D387C;
}

.labelSubQuestion {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px; //16px
  line-height: 16px;
  color: #333;
  opacity: 0.75;
  padding: 2%;
}

.labelEmail {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelEdad {
  max-width: 163px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}


.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.labelDatesMovil {
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelDatesdesktop {
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  min-height: 57px;
}

.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: center;
}

.v-btn__content {
  padding: 0.75rem;
}

.mdi-chevron-left {
  color: #002C77 !important;
}

.button-container {
  text-align: center;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.formData form.v-form div.formData div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--checkbox.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center;
}
</style>