<template>
  <div>

    <br>

    <v-row justify="center">
      <v-dialog v-model="dialogFamiliar" width="430px">
        <v-card>
          <v-card-text>
            <div>
              <v-btn icon @click="dialogFamiliar = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                        color: #FFF; flex: none; order: 1; flex-grow: 0;">Detalles del seguro de <br> Accidentes
                    Personales
                  </label>
                  <!-- <label style="font-family: Helvetica;font-style: normal;  font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                        color: #FFF; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                        font-size: 13px; line-height: 18px; letter-spacing: 0.02em; color: #FFF; flex: none; order: 1; flex-grow: 0; text-transform: uppercase; ">
                      {{ info[0][indexInfo] }}</label> del Seguro Oncológico de Southbridge.
                  </label> -->
                </div>

              </div>


              <br>
              <br>
              <div style="padding-left: 5%; ">
                <label class=" encabezado">● ¿Qué es un seguro de Muerte Accidental?</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%; margin-bottom: 10px;">
                <label class="texto">
                  En caso de que fallezcas por un accidente, tus beneficiarios recibirán el monto que haz seleccionado
                  como cobertura para apoyarlos financieramente.
                  <!-- <label class="textoAzul">reembolsaremos
                    hasta
                    el {{ info[1][indexInfo] }} de los gastos</label> del tratamiento por hasta <label
                    class="textoAzul">UF
                    {{ info[2][indexInfo] }} por cada asegurado </label>(más de 1.000 millones de pesos).
                  <br>  -->
                </label>
              </div>

              <div style="text-align: center;">
                <label class="texto"> Si quieres revisar más en detalle los <br> gastos que cubre </label><a
                  :href="document" target="_blank"> <br>
                  <div
                    style="border: solid 0.75px; margin-left: 32%; margin-right: 32%; border-radius: 5px; margin-top: 8px; ">
                    <b> haz clic
                      aquí</b>
                  </div>
                </a>
              </div>

              <br>

              <div style="padding-left: 5%;">
                <label class="encabezado"> ● ¿Cómo se declara el siniestro en caso de accidente?</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">Tus familiares deberán ingresar el siniestro en
                  <label class="textoAzul"><b> sbseguros.cl/denuncia-siniestro</b></label> para que se inicie el
                  proceso,
                  revisar los beneficiarios y continuar el proceso.
                </label>
              </div>

              <br>
              <br>

              <div style="padding-left: 5%; ">
                <label class=" encabezado"> ● Protección desde el día que contratas</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul"><b> día que
                      contratas</b></label>.
                  Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>


            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: #FFF; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogFamiliar = false">
              Aceptar
            </v-btn>
            <br>
            <br>
            <br>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogIndividual" width="430px">
        <v-card>
          <v-card-text>
            <div>
              <v-btn icon @click="dialogIndividual = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                      color: #FFF; flex: none; order: 1; flex-grow: 0;">Detalles del seguro de <br> Accidentes
                    Personales
                  </label>
                  <!-- <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                      color: #FFF; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                      font-size: 13px; line-height: 18px; letter-spacing: 0.02em; color: #FFF; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">
                      {{ info[0][indexInfo] }}</label> del Seguro <br> Oncológico de Southbridge.
                  </label> -->
                </div>

              </div>


              <br>
              <br>
              <div style="padding-left: 5%; ">
                <label class=" encabezado">● ¿Qué es un seguro de Muerte Accidental?</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%; margin-bottom: 10px;">
                <label class="texto">
                  En caso de que fallezcas por un accidente, tus beneficiarios recibirán el monto que haz seleccionado
                  como cobertura para apoyarlos financieramente.
                  <!-- <label class="textoAzul">reembolsaremos
                    hasta
                    el {{ info[1][indexInfo] }} de los gastos</label> del tratamiento por hasta <label
                    class="textoAzul">UF
                    {{ info[2][indexInfo] }} por cada asegurado </label>(más de 1.000 millones de pesos).
                  <br>  -->
                </label>
              </div>

              <div style="text-align: center;">
                <label class="texto"> Si quieres revisar más en detalle los <br> gastos que cubre </label><a
                  :href="document" target="_blank"> <br>
                  <div
                    style="border: solid 0.75px; margin-left: 32%; margin-right: 32%; border-radius: 5px; margin-top: 8px; ">
                    <b> haz clic
                      aquí</b>
                  </div>
                </a>
              </div>

              <br>

              <div style="padding-left: 5%;">
                <label class="encabezado"> ● ¿Cómo se declara el siniestro en caso de accidente?</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">Tus familiares deberán ingresar el siniestro en
                  <label class="textoAzul"><b> sbseguros.cl/denuncia-siniestro</b></label> para que se inicie el
                  proceso,
                  revisar los beneficiarios y continuar el proceso.
                </label>
              </div>

              <br>
              <br>

              <div style="padding-left: 5%; ">
                <label class=" encabezado"> ● Protección desde el día que contratas</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul"><b> día que
                      contratas</b></label>.
                  Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: #FFF; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogIndividual = false">
              Aceptar
            </v-btn>
            <br>
            <br>
            <br>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>

    <div v-if="loadingPlans == false">

      <div class="planInidivual">

        <br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planInidivualName">{{ planData.name }}</label>
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planIndividualPrecio">{{
          formatPrice(planData[0].values[0].priceCLP) }}* <label class="planIndividualSubPrecio">/ UF {{
            (planData[0].values[0].price).toFixed(2) }} mensual</label></label>

        <!-- <br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planIndividualDetalle">*Precio basado en la edad del
          contratante.</label> -->

        <br v-if="verMasIndividual == false">

        &nbsp;&nbsp;<label v-if="verMasIndividual == false" class="planIndividualSubDetalle">Cobertura de Muerte
          Accidental por UF 750</label>&nbsp;

        <br v-if="verMasIndividual == false">
        <br v-if="verMasIndividual == false">

        <div v-if="verMasIndividual == true" class="text-center">

          &nbsp;&nbsp;<label class="planIndividualSubDetalle">Cobertura de Muerte Accidental por UF 750</label>&nbsp;
          <br>

          <p></p>
          <p class="planIndividualCaracteristicas">Características</p>
          <p></p>
          <p class="planIndividualBeneficios">● {{ planData[0].coverage[0].description }}</p>
          <p></p>
          <p class="planIndividualBeneficios">● {{ planData[0].coverage[1].description }}</p>
          <p></p>
          <!-- <li class="planIndividualBeneficios">UF {{ planData[0].coverage[1].amount }}
            {{ planData[0].coverage[1].description }}</li>
          <p></p>
          <li class="planIndividualBeneficios">UF {{ planData[0].coverage[2].amount }}
            {{ planData[0].coverage[2].description }}</li> -->

          <br>

          <label class="planIndividualVerDetalle" @click="InfoIndividual()">Ver detalle</label>

          <br>
          <br>

          <v-btn class="elegirPlan" style="background-color: #FFB600;" @click="next(planData[0], 0)">
            <label style="color: #FFF;">Elegir plan</label>
          </v-btn>

          <br>
          <br>

        </div>

      </div>

      <v-expansion-panels>
        <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
          <v-expansion-panel-header class="planIndividualVerMas" style="margin-top: -2%; color: #FFF;"
            @click="VerMasIndividual()">
            <template v-slot:actions>
              <label v-if="verMasIndividual == false">Ver más</label>
              <v-icon v-if="verMasIndividual == false" style="color: #FFF; margin-top: -5%;">
                mdi-menu-down
              </v-icon>
              <label v-if="verMasIndividual == true">Ver menos</label>
              <v-icon v-if="verMasIndividual == true" style="color: #FFF; margin-top: -5%;">
                mdi-menu-up
              </v-icon>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

      <br>
      <br>

      <div class="planFamiliar" width="430px">

        <div class="planFamiliarRecomendado">

          <label class="planFamiliarConveniente">El Más conveniente</label>

        </div>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class=" text-center planFamiliarName">{{ planData[1].name }}</label>
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planFamiliarPrecio">{{
          formatPrice(planData[0].values[0].priceCLP) }}*
          <label class="planFamiliarSubPrecio">/ UF {{ (planData[0].values[0].price).toFixed(2) }}
            mensual</label></label>

        <!-- <br> -->

        <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planFamiliarDetalle">*Precio basado en la edad del
          contratante.</label> -->

        <br v-if="verMasFamiliar == false">

        &nbsp;&nbsp;<label v-if="verMasFamiliar == false" class="planFamiliarSubDetalle">Cobertura de Muerte Accidental
          por UF 1.000</label>&nbsp;&nbsp;

        <br v-if="verMasFamiliar == false">
        <br v-if="verMasFamiliar == false">

        <div v-if="verMasFamiliar == true" class="text-center">

          &nbsp;&nbsp;<label class="planFamiliarSubDetalle">Cobertura de Muerte Accidental por UF
            1.000</label>&nbsp;&nbsp;

          <p></p>
          <p class="planFamiliarCaracteristicas">Características</p>
          <p></p>

          <p class="planFamiliarBeneficios">● {{ planData[1].coverage[0].description }}</p>
          <p></p>
          <p class="planFamiliarBeneficios">● {{ planData[1].coverage[1].description }}</p>
          <p></p>
          <!-- <li class="planFamiliarBeneficios">UF {{ planData[1].coverage[1].amount }}
            {{ planData[0].coverage[1].description }}</li>
          <p></p>
          <li class="planFamiliarBeneficios">UF {{ planData[1].coverage[2].amount }}
            {{ planData[0].coverage[2].description }}</li>
          <p></p>
          <li class="planFamiliarBeneficios">Agrega a tu cónyuge, pareja, conviviente y a todos tus hijos por un único
            precio.</li> -->

          <br>

          <label class="planFamiliarVerDetalle" @click="InfoFamiliar()">Ver detalle</label>

          <br>
          <br>

          <v-btn class="elegirPlan" style="background-color: #FFB600;" @click="next(planData[1],1)">
            <label style="color: #FFF;">Elegir plan</label>
          </v-btn>

          <br>
          <br>

        </div>
      </div>

      <v-expansion-panels>
        <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
          <v-expansion-panel-header class="planFamiliarVerMas" style="margin-top: -2%; color: #FFF;"
            @click="VerMasFamiliar()">
            <template v-slot:actions>
              <label v-if="verMasFamiliar == false">Ver más</label>
              <v-icon v-if="verMasFamiliar == false" style="color: #FFF; margin-top: -5%;">
                mdi-menu-down
              </v-icon>
              <label v-if="verMasFamiliar == true">Ver menos</label>
              <v-icon v-if="verMasFamiliar == true" style="color: #FFF; margin-top: -5%;">
                mdi-menu-up
              </v-icon>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

    </div>

  </div>
</template>

<script>
import ShieldService from "@/services/shield.service.js";

import CryptoJS from 'crypto-js';

export default {
  name: "Plan",
  props: ["planData", "index", "userData", "servicesData", "planDataAux", "loadingPlans", "plansOutDesc"],
  data() {
    return {
      secretKey: process.env.VUE_APP_CRYPT,
      stepperColor: '#FFB600',
      verMasIndividual: false,
      verMasFamiliar: false,
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      stepId: null,
      e1: 3,
      info: [['Individual ISAPRE', 'Familiar ISAPRE', 'Individual FONASA', 'Familiar FONASA'], ['100%', '100%', '100%', '100%'], ['30.000', '30.000', '30.000', '30.000'], ['50', '50', '50', '50'], ['0', '24', '0', '24'], ['0', '50', '0', '50']],
      indexInfo: '0',
      dialogIndividual: false,
      dialogFamiliar: false,
      document: null,
      activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
      couponRules: [
        (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
      ],
    }
  },
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    console.log("AASDASDASD",this.plansOutDesc)
  },
  methods: {
    VerMasIndividual() {
      if (this.verMasIndividual == false) {
        this.verMasIndividual = true
      } else {
        this.verMasIndividual = false
      }
    },
    VerMasFamiliar() {
      if (this.verMasFamiliar == false) {
        this.verMasFamiliar = true
      } else {
        this.verMasFamiliar = false
      }
    },
    async SendCouponID() {
      this.planData = await ShieldService.getPlans(
        this.userData.couponId
      );
      if (this.planData.length === 0) {
        this.$alert("No se encontraron planes", "Información", "info")
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    InfoFamiliar() {
      if (this.planData[1].name == 'Plan UF 750') {
        this.indexInfo = 1
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      else if (this.planData[1].name == 'Plan UF 1000') {
        this.indexInfo = 3
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      this.dialogFamiliar = true
    },
    InfoIndividual() {
      if (this.planData[0].name == 'Plan UF 750') {
        this.indexInfo = 0
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      else if (this.planData[0].name == 'Plan UF 1000') {
        this.indexInfo = 2
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      this.dialogIndividual = true
    },
    async next(plan, index) {

      this.planDataAux.plan = plan;
      this.planDataAux.planOutDesc = this.plansOutDesc[index].values[0].priceCLP;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      sessionStorage.setItem('planData', encryptedPlanDataLocal);

      var namePlanAux = plan.name.split(' ');
      this.userData.typePlan = namePlanAux.includes('1000') ? 'UF1000' : 'UF750';
      this.userData.PlanName = namePlanAux.includes('1000') ? 'Plan 1000' : 'Plan 750';

      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      sessionStorage.setItem('userData', encryptedUserDataLocal);

      try {

        await ShieldService.newCreateStep(this.userData, null, this.planDataAux, this.servicesData, 'Planes');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      this.$router.push({ path: '/ap/beneficiarios' })

    },
  },
};
</script>

<style scoped>
.labelElige {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
}

.planInidivual {
  width: 100%;
  background: var(--AD-Degradado-2, linear-gradient(180deg, #F9F9FF 0%, #DADCFB 100%));
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;

}

.planInidivualDetalle {
  background: linear-gradient(192.38deg, #C4C6FF 3.57%, #F5F6FF 107.06%), #D8D8D8;
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 327px;
}

.planInidivualName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #002C77;
}

.planIndividualPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: normal;
  text-align: center;
  letter-spacing: -0.01em;
  color: #002C77;
}

.planIndividualSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: var(--AD-Texto-1, #333);
}

.planIndividualDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(58, 58, 58, 0.85);
}

.planIndividualSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #002C77;
}

.planIndividualVerMas {
  background: var(--AD-Primario-2, #4F74E3);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
}

.planIndividualProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
}

.planIndividualCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planIndividualBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.22px;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 172px;
  /* text-indent: -15px; */
  text-align: justify;
  margin: auto;
}

.planIndividualVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #002C77;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #FFB600;
  border: 2px solid #FFB600;
  border-radius: 8px;
}

.planFamiliar {
  width: 100%;
  /* background: var(--AD-Degradado-1, linear-gradient(180deg, #7979FF 0%, #33C 100%)); */
  background: #2D387C;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planFamiliarRecomendado {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  gap: 8px;
  width: 136px;
  height: 22px;
  border-radius: 27px;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFF;
  flex: none;
  order: 0;
  background: var(--Color-Secundario-5, #4F74E3);
  padding: 5px 25px;
  gap: 8px;
  width: 175px;
  height: 22px;
  border-radius: 27px;
  margin-top: -15%;
  margin-left: 45%;
}

.planFamiliarName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
}

.planFamiliarPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: normal;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.planFamiliarSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #FFF;
}

.planFamiliarDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
}

.planFamiliarSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}

.planFamiliarVerMas {
  background: var(--AD-Secundario-1, #4F74E3);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
  margin: auto;
}

.planFamiliarProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.planFamiliarCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.22px;
  color: #fff;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 172px;
  /* text-indent: -15px; */
  text-align: justify;
  margin: auto;
}

.planFamiliarVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #232323;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #002C77;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  display: flex;
}

#app>div>main>div>div>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__items>div>div>div:nth-child(11)>div:nth-child(2)>div {
  border-radius: 10px 10px 10px 10px;
}

.v-expansion-panel::before {
  content: none;
}
</style>