<template>
  <div>

    <v-row justify="center">
      <v-dialog v-model="dialogFamiliar" width="1000px">
        <v-card>
          <v-card-text style="text-align: justify;">
            <div>
              <v-btn icon @click="dialogFamiliar = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>


              <div
                style="background: #002C77; border-radius: 9px; text-align: center; max-width: 89%; margin-left: auto; margin-right: auto;">
                <div style="padding: 3%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                        color:#FFF; flex: none; order: 1; flex-grow: 0;">Detalles del seguro de Accidentes Personales
                  </label>
                  <!-- <label style="font-family: Helvetica;font-style: normal;  font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                        color:#FFF; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                        font-size: 22px; line-height: 18px; letter-spacing: 0.02em; color:#FFF; flex: none; order: 1; flex-grow: 0; text-transform: uppercase; ">
                      {{ info[0][indexInfo] }}</label> del <br> Seguro Oncológico de Southbridge.
                  </label> -->
                </div>


              </div>


              <br>
              <br>


              <div style="padding-left: 9%; ">
                <label class=" encabezado"> ● ¿Qué es un seguro de Muerte Accidental?
                </label>
              </div>


              <br>
              <div style="padding-left: 9%; padding-right: 10%; margin-bottom: 10px;">
                <label class="texto">
                  En caso de que fallezcas por un accidente, tus beneficiarios recibirán el monto que haz seleccionado
                  como cobertura para apoyarlos financieramente.
                  <br> </label>
              </div>
              <div style="text-align: center; ">
                <label class="texto"> Si quieres ver la póliza y conocer más detalles del seguro. <br> </label><a
                  :href="document" target="_blank"> <br>
                  <div
                    style="border: solid 0.75px; margin-left: 43%; margin-right: 43%; border-radius: 5px; margin-top: 8px; ">
                    <b>haz clic
                      aquí</b>
                  </div>
                </a>
              </div>

              <br>
              <br>

              <div style="padding-left: 9%; ">
                <label class="encabezado"> ● ¿Cómo se declara el siniestro en caso de accidente?
                </label>
              </div>

              <br>

              <div style="padding-left: 9%; padding-right: 10%;">
                <label class="texto">Tus familiares deberán ingresar el siniestro en
                  <a href="https://sbseguros.cl/denuncia-siniestro" class="textoAzul"> <b>
                      sbseguros.cl/denuncia-siniestro</b></a> para que se inicie el
                  proceso, revisar los beneficiarios y continuar el proceso.
                </label>
              </div>

              <br>
              <br>

              <div style="padding-left: 9%;">
                <label class=" encabezado"> ● Protección desde el día que contratas</label>
              </div>


              <br>
              <div style="padding-left: 9%; padding-right: 10%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul"><b> día que
                      contratas. </b></label>
                  Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color:#FFF; flex: none; order: 0; flex-grow: 0; margin:auto;" elevation="0"
              text @click="dialogFamiliar = false">
              Aceptar

            </v-btn>
          </v-card-actions>
          <br>
          <br>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogIndividual" width="1000px">
        <v-card>
          <v-card-text style="text-align: justify;">
            <div>
              <v-btn icon @click="dialogIndividual = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div
                style="background: #002C77; border-radius: 9px; text-align: center; max-width: 89%; margin-left: auto; margin-right: auto;">
                <div style="padding: 3%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                      color:#FFF; flex: none; order: 1; flex-grow: 0;">Detalles del seguro de Accidentes Personales
                  </label>
                  <!-- <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                      color:#FFF; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                      font-size: 22px; line-height: 18px; letter-spacing: 0.02em; color:#FFF; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">
                      {{ info[0][indexInfo] }}</label> <br> del Seguro Oncológico de www.sbseguros.cl.
                  </label> -->
                </div>

              </div>


              <br>
              <br>


              <div style="padding-left: 9%; ">
                <label class=" encabezado"> ● ¿Qué es un seguro de Muerte Accidental?
                </label>
              </div>


              <br>
              <div style="padding-left: 9%; padding-right: 10%; margin-bottom: 10px;">
                <label class="texto">
                  En caso de que fallezcas por un accidente, tus beneficiarios recibirán el monto que haz seleccionado
                  como cobertura para apoyarlos financieramente.
                  <br> </label>
              </div>
              <div style="text-align: center; ">
                <label class="texto"> Si quieres ver la póliza y conocer más detalles del seguro. <br> </label><a
                  :href="document" target="_blank"> <br>
                  <div
                    style="border: solid 0.75px; margin-left: 43%; margin-right: 43%; border-radius: 5px; margin-top: 8px; ">
                    <b>haz clic
                      aquí</b>
                  </div>
                </a>
              </div>

              <br>
              <br>

              <div style="padding-left: 9%; ">
                <label class="encabezado"> ● ¿Cómo se declara el siniestro en caso de accidente?
                </label>
              </div>

              <br>

              <div style="padding-left: 9%; padding-right: 10%;">
                <label class="texto">Tus familiares deberán ingresar el siniestro en
                  <a href="https://sbseguros.cl/denuncia-siniestro" class="textoAzul"> <b>
                      sbseguros.cl/denuncia-siniestro</b></a> para que se inicie el
                  proceso, revisar los beneficiarios y continuar el proceso.
                </label>
              </div>

              <br>
              <br>

              <div style="padding-left: 9%;">
                <label class=" encabezado"> ● Protección desde el día que contratas</label>
              </div>

              <br>
              <div style="padding-left: 9%; padding-right: 10%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul"><b> día que
                      contratas</b></label>. Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color:#FFF; flex: none; order: 0; flex-grow: 0; margin:auto;" elevation="0"
              text @click="dialogIndividual = false">
              Aceptar
            </v-btn>
          </v-card-actions>
          <br>
          <br>
        </v-card>
      </v-dialog>

    </v-row>

    <div v-if="loadingPlans == false">

      <div class="plan" v-if="planData.name == 'Plan UF 1000' || planData.name == 'Plan UF 1000'">

        <v-col class="text-center">

          <!-- <div class="planFamiliarRecomendado">

            <label class="planFamiliarConveniente">El Más conveniente</label>

          </div> -->

          <div v-if="planDataAux.setDesc" class="superpuestoFam" >
            <p class="p-descFam"  style="margin-bottom: 0px !important;padding-top: 9px;">{{ planDataAux.valueDesc }} %  </p> 
            <p class="p-descFam"  style="margin-bottom: 0px !important;font-size: 12px;">DCTO</p> 
          </div>

          <p class="planFamiliarName">{{ planData.name }}</p>

          <p></p>

          <p class="planFamiliarPrecio">{{ formatPrice(planData.values[0].priceCLP) }}</p>

          <p class="planFamiliarSubPrecio">/ UF {{ (planData.values[0].price).toFixed(2) }} mensual</p>

          <label class="planFamiliarSubDetalle"><b>Cobertura de Muerte Accidental por UF 1.000</b></label>
          <br><br>
          <hr class="planFamiliarLinea">

          <br>

          <!-- <p class="planFamiliarProtege">Protégete junto a toda tu familia por una tarifa única.</p>

          <br> -->


          <label class="planFamiliarCaracteristicas">Características</label>

          <br>
          <br>

          <li class="planFamiliarBeneficios">{{ planData.coverage[0].description }}</li>
          <br>
          <li class="planFamiliarBeneficios">{{ planData.coverage[1].description }}</li>
          <!-- <p></p>
          <li class="planFamiliarBeneficios">UF {{ planData.coverage[1].amount }} {{ planData.coverage[1].description }}
          </li>
          <p></p>
          <li class="planFamiliarBeneficios">UF {{ planData.coverage[2].amount }} {{ planData.coverage[2].description }}
          </li>
          <p></p>
          <li class="planFamiliarBeneficios">Agrega a tu cónyuge, pareja, conviviente y a todos tus hijos por un único
            precio.</li> -->
          <p></p>

          <br>

          <hr class="planFamiliarLinea">

          <div class="plan-info">

            <button>

              <u class="planFamiliarVerDetalle" elevation="0" @click="InfoFamiliar()">
                VER DETALLE
              </u>

            </button>

          </div>

        </v-col>

        <br>

        <v-btn class="elegirPlan" style="background-color: #4F74E3;" @click="validate()">
          <label style="color:#FFF;">Seleccionar</label>

        </v-btn>

      </div>

      <div class="plan2" v-if="planData.name == 'Plan UF 750' || planData.name == 'Plan UF 750'">

        <div v-if="planDataAux.setDesc" class="superpuesto" >
          <p class="p-desc"  style="margin-bottom: 0px !important; margin-top: 9px;">{{ planDataAux.valueDesc }} %  </p> 
          <p class="p-desc"  style="margin-bottom: 0px !important;font-size: 12px;">DCTO</p> 
        </div>

        <v-col class="text-center">

          <br>

          <p class="planIndividualName">{{ planData.name }}</p>

          <p></p>

          <p class="planIndividualPrecio">{{ formatPrice(planData.values[0].priceCLP) }}</p>

          <p class="planIndividualSubPrecio">/ UF {{ (planData.values[0].price).toFixed(2) }} mensual</p>

          <label class="planIndividualSubDetalle"> <b> Cobertura de Muerte Accidental por UF 750</b></label>
          <br><br>
          <hr class="planIndividualLinea">

          <br>

          <!-- <p class="planIndividualProtege">Protégete junto a toda tu familia por una tarifa única.</p>

          <br> -->

          <label class="planIndividualCaracteristicas">Características</label>

          <br>
          <br>

          <li class="planIndividualBeneficios">{{ planData.coverage[0].description }}
          </li>
          <br>
          <li class="planIndividualBeneficios">{{ planData.coverage[1].description }}
          </li>
          <!-- <p></p>
          <li class="planIndividualBeneficios">UF {{ planData.coverage[1].amount }} {{ planData.coverage[1].description }}
          </li>
          <p></p>
          <li class="planIndividualBeneficios">UF {{ planData.coverage[2].amount }} {{ planData.coverage[2].description }}
          </li> -->

          <!-- <br>
          <br>
          <br> -->
          <br>

          <hr class="planIndividualLinea" style="margin-top: 8%;">

          <div class="plan-info">

            <button>

              <u class="planIndividualVerDetalle" elevation="0" @click="InfoIndividual()">
                VER DETALLE
              </u>

            </button>


          </div>

        </v-col>

        <br>

        <v-btn class="elegirPlan" style="background-color: #4F74E3;" @click="validate()">
          <label style="color:#FFF;">Seleccionar</label>
        </v-btn>

      </div>

    </div>

  </div>
</template>

<script>
import LibreEleccion from "../assets/images/libreeleccion.png"
import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js';

export default {
  name: "Plan",
  props: ["planData", "index", "userData", "servicesData", "planDataAux", "loadingPlans", "plansOutDesc"],
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    stepId: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    LibreEleccion,
    info: [['Individual ISAPRE', 'Familiar ISAPRE', 'Individual FONASA', 'Familiar FONASA'], ['100%', '100%', '100%', '100%'], ['30.000', '30.000', '30.000', '30.000'], ['50', '50', '50', '50'], ['0', '24', '0', '24'], ['0', '50', '0', '50']],
    indexInfo: '0',
    dialogIndividual: false,
    dialogFamiliar: false,
    document: null,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
  }),
  methods: {
    InfoFamiliar() {
      if (this.planData.name == 'Plan UF 750') {
        this.indexInfo = 1
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      else if (this.planData.name == 'Plan UF 1000') {
        this.indexInfo = 3
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      this.dialogFamiliar = true
    },
    InfoIndividual() {
      if (this.planData.name == 'Plan UF 750') {
        this.indexInfo = 0
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      else if (this.planData.name == 'Plan UF 1000') {
        this.indexInfo = 2
        this.document = '/documents/Detalle_Seguro_Accidentes_Personales.pdf'
      }
      this.dialogIndividual = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf) {
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    async validate() {

      this.planDataAux.plan = this.planData;
      this.planDataAux.planOutDesc = this.plansOutDesc[this.index].values[0].priceCLP;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      sessionStorage.setItem('planData', encryptedPlanDataLocal);

      var namePlanAux = this.planData.name.split(' ');
      this.userData.typePlan = namePlanAux.includes('1000') ? 'UF1000' : 'UF750';
      this.userData.PlanName = namePlanAux.includes('1000') ? 'Plan 1000' : 'Plan 750';

      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      sessionStorage.setItem('userData', encryptedUserDataLocal);

      try {

        await ShieldService.newCreateStep(this.userData, null, this.planDataAux, this.servicesData, 'Planes');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      this.$router.push({ path: '/ap/beneficiarios' });

    },
    parseNumber(n) {
      return new Intl.NumberFormat().format(n);
    },
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.planFamiliarRecomendado {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  gap: 8px;
  width: 171px;
  height: 22px;
  background: var(--AD-Secundario-1, #4F74E3);
  border-radius: 5px;
  margin: auto;
  margin-top: 5%;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  width: 200px;
  margin: auto;
  margin-top: 5%;
}

.planFamiliarPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin: auto;
}

.planFamiliarSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #FFF;
}

.planFamiliarSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: normal;
  color: #FFF;
  ;
}

.planFamiliarLinea {
  width: 214px;
  height: 0px;
  border: 0.946207px solid #FFFFFF;
  margin: auto;
}

.planFamiliarProtege {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin: auto;
  width: 160px;
}

.planFamiliarCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  text-indent: -25px;
  text-align: justify;
  margin: auto;
}

.planFamiliarVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 238px;
  height: 37px;
  background: #4F74E3;
  border: 2px solid #4F74E3;
  border-radius: 8px;
}

.planIndividualName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #343497;
  width: 200px;
  margin: auto;
  margin-top: 10%;
}

.planIndividualPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #343497;
  margin: auto;
}

.planIndividualSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: var(--AD-Texto-1, #333);
}

.planIndividualSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: normal;
  color: var(--AD-Primario-1, #2D387C);
}

.planIndividualLinea {
  width: 214px;
  height: 0px;
  border: 0.946207px solid rgba(0, 44, 119, 1);
  margin: auto;
}

.planIndividualProtege {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  margin: auto;
  width: 160px;
}

.planIndividualCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--AD-Primario-1, #2D387C);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planIndividualBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  text-indent: -25px;
  text-align: justify;
  margin: auto;
}

.planIndividualVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #4040A3;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #232323;
  flex: none;
  order: 1;
  flex-grow: 0;

}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #002C77;
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  display: flex;

}

.plan-info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan {
  background: linear-gradient(180deg, #2A3881 -39.82%, #2A3881 60.81%);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px 10px 10px 10px;
  width: 266px;
}

.plan2 {
  background: var(--AD-Degradado-2, linear-gradient(180deg, #D8D8D8 0%, #D8D8D8 100%));
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px 10px 10px 10px;
  width: 266px;
  margin-right: 30px;
}

.superpuesto {
  content: 'Nuevo';
  margin-top: -4% !important;
  width: 55px !important;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  background: #4F74E3;
  border-radius: 0px 10px 0px 20px;
  margin-left: 269px;
  margin-bottom: -25px;
}

.p-desc {
  // font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin: auto;
}

.superpuestoFam {
  content: 'Nuevo';
  margin-top: -4.4% !important;
  width: 55px !important;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: #2D387C;
  font-size: 14px;
  font-weight: bold;
  background: #ECECEC;
  border-radius: 0px 00px 0px 10px;
  margin-left: 243px;
  margin-bottom: -15px;
}

.p-descFam {
  // font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2D387C;
  margin: auto;
}
</style>
